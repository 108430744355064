<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="appStore.sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!settingsStore.topNav" />
    <top-nav id="topmenu-container" class="topmenu-container" v-if="settingsStore.topNav" />

    <div class="right-menu">
      <template v-if="appStore.device !== 'mobile'">
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
        <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
      </template>
      <changeversion class="right-menu-item hover-effect" @click="getNew" id="stepRow" title="切换新版本"
        style="font-size: 30px" v-if="userStore.isShowGemini" />
      <div class="avatar-container">
        <el-dropdown @command="handleCommand" class="right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <img :src="userStore.avatar" class="user-avatar" />
            <span class="user-name">{{ userStore.name }}</span>
            <el-icon>
              <caret-bottom />
            </el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item command="setLayout">
                <span>布局设置</span>
              </el-dropdown-item>
              <el-dropdown-item divided command="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <global-station-select v-if="isShow" />
  </div>
</template>

<script setup>
import { ElMessageBox } from "element-plus";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import GlobalStationSelect from "./GlobalStationSelect.vue";
// import HeaderSearch from '@/components/HeaderSearch'
import changeversion from "@/components/ChangeVersion/index.vue";
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
import useSettingsStore from "@/store/modules/settings";
import { computed, ref } from "vue";
import router from "@/router";
import Cookies from "js-cookie";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { getToken } from "@/utils/auth";

const appStore = useAppStore();
const userStore = useUserStore();
const settingsStore = useSettingsStore();

function toggleSideBar() {
  appStore.toggleSideBar();
}

function handleCommand(command) {
  switch (command) {
    case "setLayout":
      setLayout();
      break;
    case "logout":
      logout();
      break;
    default:
      break;
  }
}

function logout() {
  ElMessageBox.confirm("确定注销并退出系统吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      userStore.logOut().then(() => {
        location.href = "/";
      });
    })
    .catch(() => { });
}

const emits = defineEmits(["setLayout"]);
function setLayout() {
  emits("setLayout");
}

const hideList = [
  "/index",
  "/station-monitor/map",
  "/vpp/enterpriseInfo",
  "/vpp/overview",
  "/vpp/reportHistory",
  "/vpp/datalog",
  "/vpp/baseline",
  "/vpp/report",
  "/vpp/accessManagement",
  "/vpp/entrusted",
  "/vpp/settlement",
  "/vpp/revenue",
  "/config",
  "/monitor",
  "/system",
  '/operation/summary-data',
  '/production/meter_state',
  '/user/profile',
  '/station-monitor/air-detail',
];

const isShow = computed(() => {
  let route = router.currentRoute.value;
  let menu = route.matched.filter((item) => item.meta && item.meta.title);
  let booleanMenu = menu.some((menuItem) => {
    return hideList.includes(menuItem.path);
  });
  return !booleanMenu;
});

const getNew = () => {
  driverObj.value && driverObj.value.destroy();
  ElMessageBox.confirm("是否切换版本", "系统提示", {
    confirmButtonText: "切换新版",
    cancelButtonText: "留在旧版",
    type: "warning",
  }).then(() => {
    router.push({
      path: "/",
      // query: {
      //   t: Date.now(),
      // },
    });

    setTimeout(() => {
      Cookies.set("isNew", 1);
      location.pathname = "/";
      location.reload();
    }, 1000);
  });
};
const driverObj = ref(null);

onMounted(() => {
  console.log(userStore.isShowGemini);
  // if (!Cookies.get('isNew')) {
  //   driverObj.value = driver({
  //     popoverClass: 'driverjs-theme'
  //   });

  //   driverObj.value.highlight({
  //     element: "#stepRow",
  //     popover: {
  //       title: "新功能提示",
  //       description: "点击此图标可选择切换系统版本"
  //     }
  //   })
  //   setTimeout(() => {
  //     driverObj.value && driverObj.value.destroy();
  //   }, 3000)
  // }
});
</script>

<style lang='scss' scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: var(--color-fg);
  // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background-color 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 40px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 20px;
          top: 4px;
        }

        .user-name {
          position: relative;
          font-size: 14px;
          padding: 0 5px;
          top: -4px;
        }

        i {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 14px;
          font-size: 12px;
        }
      }
    }
  }
}


</style>
