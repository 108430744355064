import { ElMessageBox, dayjs } from 'element-plus'
import { parseTime } from './ruoyi'
import { init } from 'echarts'
/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
  if (cellValue == null || cellValue == "") return ""
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}


export function formatNum(num) {
  return String(num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

export function makeMap(str, expectsLowerCase) {
  const map = Object.create(null)
  const list = str.split(',')
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true
  }
  return expectsLowerCase
    ? val => map[val.toLowerCase()]
    : val => map[val]
}

export const exportDefault = 'export default '

export const beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

// 下划转驼峰
export function camelCase(str) {
  return str.replace(/_[a-z]/g, str1 => str1.substr(-1).toUpperCase())
}

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
}

/**
 * 数字长度
 * @param {number} num 数字
 * @returns {number}
 */
export function numLength(num) {
  num = Number(num)
  let len = 0
  while (num > 0) {
    num = Math.floor(num / 10)
    len++
  }
  return len
}

export function getRawType(value) {
  return Object.prototype.toString.call(value).slice(8, -1)
}

/**
 * 格式化数字
 * @param {number} value 值
 * @param {number} amount 乘数
 * @param {number} fractionDigits 保留位数
 * @returns {string}
 */
export function fmtd(value, amount = 1, fractionDigits = 1) {
  if (value === void 0) {
    return '--'
  }
  return (value * amount).toFixed(fractionDigits)
}

/**
 * 千分位数字
 * @param {*} value 值
 * @param {*} amount 乘的系数
 * @param {*} digits 保留位数
 * @param {*} locales 本地化
 * @returns 
 */
export function toLocal(value, amount = 1, digits = 0, locales) {
  if (value != null) {
    value = (value * amount).toFixed(digits)
    return Number(value).toLocaleString(locales)
  }
  return '--'
}

/**
 * 生成 start, end 之间的随机数
 * @param {number} start
 * @param {number} end
 * @returns {number}
 */
export function random(start, end) {
  let n = Math.floor(Math.random() * (end - start + 1))
  return start + n
}

/**
 * 判断非空值 [] {} null undefined NaN '' 都为空
 * @param {*} value 
 * @returns 
 */
export function exist(value) {
  let valueType = getRawType(value)

  if (valueType === 'BigInt') {
    return true
  }

  if (valueType === 'Number') {
    return !Number.isNaN(value)
  }

  if (valueType === 'Array') {
    return value.length > 0
  }

  if (valueType === 'String') {
    return value.length > 0
  }

  if (valueType === 'Object') {
    return Object.keys(value).length > 0
  }

  return false
}



/**
 * 加载数据
 * @param {AsyncFunction} asyncFunc 加载函数(必须是异步函数)
 * @param {Object} context 组件上下文
 * @param {String} ctrlKey 控制loading的字段
 * @returns
 */
export function __loadRemoteData(asyncFunc, context, ctrlKey = 'loading') {
  if (!context) {
    throw new Error('require context')
  }
  return async (...args) => {
    try {
      context[ctrlKey] = true
      return await asyncFunc(...args)
    } catch (error) {
      console.warn(error)
    } finally {
      context[ctrlKey] = false
    }
  }
}

/**
 * 解包数据
 * @param {*} fn api函数
 * @param {*} key 数据名称
 * @returns 
 */
export function unwrap(fn, key = 'rows') {
  return function (...args) {
    return new Promise((resolve, reject) => {
      fn(...args).then(res => {
        if (res.code == 200) {
          resolve(res[key])
        } else {
          reject(new Error('请求失败'))
        }
      }).catch(e => {
        reject(e)
      })
    })
  }
}

/**
 * 
 * @param {*} delay 
 * @returns 
 */
export function sleep(delay) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  })
}


/**
 * 初始化echart
 * @param {*} el dom元素
 * @param {*} defaultOption 配置项
 * @returns {EchartInstance}
 */
export function initChart(el, defaultOption) {
  let chart = init(el)
  chart.setOption(defaultOption)
  window.addEventListener('resize', () => {
    chart.resize()
  })
  return chart
}


/**
 * 数据操作对象
 * @param {*} type  localStorage or sessionStorage
 * @returns 
 */
export function createStorage(type = 'localStorage', key = 'eMind_v2') {

  if (type != 'localStorage' && type != 'sessionStorage') {
    throw new TypeError('参数错误')
  }

  let Storage = {
    sessionStorage,
    localStorage
  }

  let storage = Storage[type]

  function getStorage() {
    try {
      let str = storage.getItem(key) || '{}'
      return JSON.parse(str)
    } catch (error) {
      console.warn(error)
      return {}
    }
  }

  return {
    save(name, value) {
      var obj = getStorage()
      obj[name] = value
      storage.setItem(key, JSON.stringify(obj))
    },
    load(name, defaultValue) {
      var obj = getStorage()
      if (name) {
        return obj[name] ?? defaultValue
      }
      return obj
    },
    remove(name) {
      var obj = getStorage()
      delete obj[name]
      storage.setItem(key, JSON.stringify(obj))
    },
    clear() {
      var obj = {}
      storage.setItem(key, JSON.stringify(obj))
    }
  }
}


export const KB = 1024;
export const MB = KB * KB;
export const GB = MB * KB;
export const kMicro2Milli = 1 / 1000;

export function formatBytes(bytes) {
  const units = ['B', 'KiB', 'MiB', 'GiB'];
  const divisor = 1024;
  let index = 0;
  while (index < units.length && bytes >= divisor) {
    index++;
    bytes /= divisor;
  }
  return bytes.toFixed(2) + ' ' + units[index];
}

export function cusConfirm(message) {
  return new Promise(resolve => {
    ElMessageBox.confirm(message, {
      title: "系统提示",
      type: "warning",
    }).then(() => resolve(true)).catch(() => resolve(false));
  });
}

/**
 * 获得时间范围 例如 ['2024-01-01 00:00:00', '2024-01-01 23:59:59']
 * @param {Array} dateRange 
 * @param {string} startformatStr 
 * @param {string} endformatStr 
 * @returns 
 */
export function getFormattedDateRangeByArray(dateRange = [new Date, new Date], startformatStr = 'YYYY-MM-DD 00:00:00', endformatStr = 'YYYY-MM-DD 23:59:59') {
  const [st, et] = dateRange
  const fmt = (d, formatStr) => {
    return dayjs(d).format(formatStr)
  }
  return [fmt(st, startformatStr), fmt(et, endformatStr)]
}

/**
 * 复制文本到剪贴板
 * @param {*} text 
 * @returns 
 */
export function copyText(text) {
  var textarea = document.createElement('textarea');
  textarea.setAttribute('readonly', 'readonly'); //设置只读属性防止手机上弹出软键盘
  textarea.value = text;
  document.body.appendChild(textarea); //将textarea添加为body子元素
  textarea.select();
  var res = document.execCommand('copy');
  document.body.removeChild(textarea);//移除DOM元素
  return res;
}


export function useAutoLoading(defaultLoading = false) {
  const loading = ref(defaultLoading);

  function run(requestPromise) {
    loading.value = true;
    return requestPromise.then(res => {
      if (res?.code === 200) {
        return Promise.resolve(res)
      } else {
        return Promise.reject(new Error('请求失败'))
      }
    }).catch(e => {
      return Promise.reject(e)
    }).finally(() => {
      loading.value = false;
    });
  }

  return [loading, run];
}


export function fomatFloat(value, n) {
  var f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n)
  return f
}
