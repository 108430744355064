var t = `%c
######## ##     ## #### ##    ## ########      ######  ##        #######  ##     ## ########  
##       ###   ###  ##  ###   ## ##     ##    ##    ## ##       ##     ## ##     ## ##     ## 
##       #### ####  ##  ####  ## ##     ##    ##       ##       ##     ## ##     ## ##     ## 
######   ## ### ##  ##  ## ## ## ##     ##    ##       ##       ##     ## ##     ## ##     ## 
##       ##     ##  ##  ##  #### ##     ##    ##       ##       ##     ## ##     ## ##     ## 
##       ##     ##  ##  ##   ### ##     ##    ##    ## ##       ##     ## ##     ## ##     ## 
######## ##     ## #### ##    ## ########      ######  ########  #######   #######  ########`
console.log(t, 'color: #2769F3; font-size: 12px')
console.log('%c----------------------------', 'color: #104DA8;')
console.log('%c让稳定的清洁电力惠及每一个人', 'color: #104DA8; font-size: 20px')
console.log('%cReliable Clean Power for All', 'color: #104DA8;')
console.log('%c----------------------------', 'color: #104DA8;')

/* 处理打印 */
const isDev = process.env.NODE_ENV === 'development';
const log = console.log.bind(console);
window.log = console.log = function () {/*  */ };
if (isDev) {
  window.log = console.log = log;
}
