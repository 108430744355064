<template>
  <div class="g-station-name">
    <span v-if="isShow">{{ appStore.stationName }}</span>
    <div class="g-station-select">
      <el-select ref="ssRef" v-show="!isShow" v-model.number="stationId" filterable class="select-list"
        @change="delayClose" @blur="delayClose">
        <el-option :value="station.stationId" v-for="station in stations" :label="station.name">
          <div class="flex justify-between items-center">
            <span>{{ station.name }}</span>
            <el-tag :type="station.meterModel ? 'success' : 'info'"
              style="color: var(--el-text-color-secondary);font-size: 13px;">
              {{ station.meterModel ? '实体表' : '虚拟表' }}
            </el-tag>
          </div>
        </el-option>
      </el-select>
      <el-tooltip :content="tipText" v-if="isShow">
        <div class="ml-2 flex items-center bg-gray-100 hover:bg-blue-200 rounded cursor-pointer p-2" @click="onSearchClick">
          <el-icon style="font-size: 14px;">
            <Sort />
          </el-icon>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>
<script setup>
import useAppStore from '@/store/modules/app'
import { Search, Sort } from '@element-plus/icons-vue'
import { computed, nextTick, ref } from 'vue'

const appStore = useAppStore()
const stations = computed(() => appStore.stations);
const stationId = computed({
  get: () => appStore.stationId,
  set: val => {
    appStore.setStationId(val)
  }
})

const emit = defineEmits(['onExpand'])

if (!stationId.value) {
  appStore.getStations()
}

const ssRef = ref(null)
const isShow = ref(true)
const tipText = computed(() => {
  // return isShow.value ? '收起电站选择' : '展开电站选择'
  return '选择电站'
})



function onSearchClick() {
  isShow.value = !isShow.value
  emit('onExpand', isShow.value)

  if (!isShow.value) {
    nextTick(() => {
      ssRef.value.focus()
    })
  }

}

function delayClose() {
  setTimeout(() => {
    isShow.value = true
  }, 500)  
}


</script>

<style scoped lang="scss">

.g-station-name {
  display: flex;
  align-items: center;
  line-height: 50px;
  height: 50px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

@media screen and (max-width: 1200px) {
  .g-station-name {
    display: none;
  }
}

.g-station-select {
  position: relative;
  top: 2px;
  margin-right: 8px;
  line-height: 50px;
  display: flex;
  align-items: center;
  margin-left: 8px;

  .select-list {
    width: 482px;
    transition: width 0.2s;
    overflow: hidden;
  }
}

</style>
